// This file will get hardcoded during build time in each html file
// @see ~frontend-components/packages/sharedTheme.ts for available options
// Note: You don't have to define all components properties, by default they are derived from "colorPrimary" levels properties

const COLORS = {
    garantiGreen: '#00632F',
    garantiWhite: '#FFFFFF',
    garantiGray: '#3C3C3C',
    garantiGray2: '#F5F5F5',
    garantiGray3: '#8C8C8C'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).componentsTheme = {
    colorPrimary: COLORS.garantiGreen,
    boxShadowFocus: '0 0 0 0 rgba(0, 0, 0, 0)',
    button: {
        colors: {
            primary: {
                background: COLORS.garantiGreen,
                border: COLORS.garantiGreen
            },
            secondary: {
                color: COLORS.garantiGreen,
                background: COLORS.garantiWhite,
                border: COLORS.garantiGreen
            }
        },
        hoverColors: {
            primary: {
                background: COLORS.garantiGreen,
                border: COLORS.garantiGreen
            },
            secondary: {
                color: COLORS.garantiGreen,
                background: COLORS.garantiWhite,
                border: COLORS.garantiGreen
            }
        },
        minWidth: '150px'
    },
    datePicker: {
        input: {
            fontFamily: 'Source Sans Pro SemiBold',
            fontSize: '13px'
        },
        day: {
            todayColor: COLORS.garantiGreen,
            todayBorder: `solid 1px ${COLORS.garantiGreen}`
        },
        'day:selected': {
            background: COLORS.garantiGreen
        },
        'day:hover:selected': {
            color: COLORS.garantiWhite,
            background: COLORS.garantiGray2
        },
        'day:disabled': {
            color: COLORS.garantiGray3,
            background: COLORS.garantiGray2
        }
    },
    grid: {
        header: {
            fontFamily: 'Source Sans Pro SemiBold',
            fontWeight: 'bold'
        },
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        colorPaginationBackgroundSelected: COLORS.garantiGreen,
        colorColumnSorted: COLORS.garantiGreen
    },
    dataGrid: {
        header: {
            fontFamily: 'Source Sans Pro SemiBold',
            fontWeight: 'bold'
        },
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        colorPaginationBackgroundSelected: COLORS.garantiGreen,
        colorColumnSorted: COLORS.garantiGreen,
        colorPaginationHover: COLORS.garantiGreen
    },
    modalDialog: {
        width: '450px',
        padding: 0,
        borderRadius: '0',
        borderWidth: 0,
        header: {
            padding: '15px 20px',
            margin: '0',
            color: COLORS.garantiGray,
            fontWeight: '400',
            fontSize: '16px',
            showIcon: false
        },
        closeIcon: {
            color: COLORS.garantiGreen
        },
        footer: {
            padding: '40px 20px 20px 20px',
            margin: '0'
        },
        body: {
            fontSize: '12px',
            color: COLORS.garantiGray,
            width: '410px',
            padding: '20px 0 0 0',
            minHeight: '90px'
        },
        confirmationDialog: {
            footer: {},
            header: {}
        }
    },
    select: {
        optionColorSelected: COLORS.garantiGreen,
        optionColorHover: COLORS.garantiGreen,
        fontSize: '13px'
    },
    tabs: {
        fontFamily: 'Source Sans Pro, Arial, sans-serif'
    }
};
